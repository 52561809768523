import React from "react";
import { graphql } from "gatsby";
import { Solutions } from "../Solutions";

import "./styles.css";

const HouseKitchenSolutions = ({
  background,
  title,
  name,
  subtitle,
  systems,
  solutions
}) => {
  return (
    <div id="kitchen">
      <Solutions
        background={background}
        title={title}
        name={name}
        subtitle={subtitle}
        systems={systems}
        solutions={solutions}
      />
    </div>
  );
};

HouseKitchenSolutions.getLivePreviewData = ({ data, getAsset }) => ({
  kitchenSolutions: {
    ...data.kitchenSolutions,
    background: getAsset(data.kitchenSolutions.background).url,
    solutions: data.kitchenSolutions.solutions.map(solution => ({
      ...solution,
      photo: getAsset(solution.photo).url
    }))
  }
});

const houseKitchenSolutionsQuery = graphql`
  fragment HouseKitchenSolutionsFragment on MarkdownRemarkFrontmatter {
    kitchenSolutions {
      background {
        childImageSharp {
          fluid(maxWidth: 1544) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      title
      name
      subtitle
      systems
      solutions {
        photo {
          childImageSharp {
            fluid(maxWidth: 936) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
        text
        price
      }
    }
  }
`;

export { HouseKitchenSolutions, houseKitchenSolutionsQuery };
