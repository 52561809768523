import { graphql } from "gatsby";
import React from "react";
import Helmet from "react-helmet";
import { HouseHero } from "../components/HouseHero";
import { HouseHouseSolutions } from "../components/HouseHouseSolutions";
import { HouseHowWeWork } from "../components/HouseHowWeWork";
import { HouseIssues } from "../components/HouseIssues";
import { HouseKitchenSolutions } from "../components/HouseKitchenSolutions";
import { Layout } from "../components/Layout";
import { Maintenance } from "../components/Maintenance";

const HousePageTemplate = ({ layout, ...props }) => {
  return (
    <>
      <Helmet>
        <title>{props.seo.title}</title>
        <meta name="Description" content={props.seo.description} />
        <meta name="keywords" content={props.seo.keywords} />
      </Helmet>

      <HouseHero {...props.hero} />
      <HouseIssues {...props.issues} />
      <HouseHouseSolutions {...props.houseSolutions} />
      <HouseKitchenSolutions {...props.kitchenSolutions} />
      <HouseHowWeWork pageId={props.pageId} {...props.howWeWork} />
      {layout.maintenance && <Maintenance {...layout.maintenance} secondary />}
    </>
  );
};

HousePageTemplate.getLivePreviewData = (props) => ({
  ...props.data,
  ...Layout.getLivePreviewData(props),
  ...HouseHero.getLivePreviewData(props),
  ...HouseIssues.getLivePreviewData(props),
  ...HouseHouseSolutions.getLivePreviewData(props),
  ...HouseKitchenSolutions.getLivePreviewData(props),
  ...HouseHowWeWork.getLivePreviewData(props),
});

const HousePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout
      {...data.layout.frontmatter}
      pageId={frontmatter.pageId}
      path={frontmatter.path}
    >
      <HousePageTemplate layout={data.layout.frontmatter} {...frontmatter} />
    </Layout>
  );
};

const pageQuery = graphql`
  query HousePageTemplate {
    layout: markdownRemark(frontmatter: { meta: { eq: "layout" } }) {
      frontmatter {
        ...LayoutFragment
        ...MaintenanceFragment
      }
    }

    markdownRemark(frontmatter: { templateKey: { eq: "house-page" } }) {
      frontmatter {
        pageId
        path
        seo {
          title
          description
          keywords
        }
        templateKey
        ...HouseHeroFragment
        ...HouseIssuesFragment
        ...HouseHouseSolutionsFragment
        ...HouseKitchenSolutionsFragment
        ...HouseHowWeWorkFragment
      }
    }
  }
`;

export default HousePage;

export { pageQuery, HousePageTemplate };
