import { graphql, Link } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import React from "react";
import { House } from "../../icons/House";
import { Knife } from "../../icons/Knife";
import { Button } from "../Button";
import { HeroCard } from "../HeroCard";
import "./styles.css";

const HouseHero = ({
  background,
  title,
  text,
  buttonLabel,
  houseFilters,
  kitchenFilters,
}) => {
  return (
    <div className="HouseHero">
      <BackgroundImage
        className="HouseHero__hero"
        fluid={background.childImageSharp.fluid}
      >
        <div className="[ HouseHero__content ] [ Content md:Content_small sm:Content_medium ]">
          <h1 className="[ HouseHero__title ] [ Color_darkBlue Font_headerMiddle sm:Font_headerSmallMiddle ]">
            {title}
          </h1>
          <div className="[ HouseHero__tagline ] [ Color_darkBlue Font_bigText sm:Font_smallRegularText ]">
            {text}
          </div>
          <Link to="/house/#contact-us">
            <Button className="HouseHero__button">{buttonLabel}</Button>
          </Link>
        </div>
      </BackgroundImage>
      <div className="HouseHero__cardsContainer">
        <div className="[ HouseHero_cards ] [ Content md:Content_small sm:Content_medium ]">
          <HeroCard
            className="House_card"
            {...houseFilters}
            Icon={House}
            href="/house/#house"
          />
          <HeroCard
            className="House_card"
            {...kitchenFilters}
            Icon={Knife}
            href="/house/#kitchen"
          />
        </div>
      </div>
    </div>
  );
};

HouseHero.getLivePreviewData = ({ data, getAsset }) => ({
  hero: {
    ...data.hero,
    background: {
      childImageSharp: {
        fluid: {
          src: getAsset(data.hero.background).url,
        },
      },
    },
  },
});

const houseHeroQuery = graphql`
  fragment HouseHeroFragment on MarkdownRemarkFrontmatter {
    hero {
      background {
        childImageSharp {
          fluid(maxWidth: 2880, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      title
      text
      buttonLabel

      houseFilters {
        title
        text
        buttonLabel
      }

      kitchenFilters {
        title
        text
        buttonLabel
      }
    }
  }
`;

export { HouseHero, houseHeroQuery };
