import { graphql } from "gatsby";
import React from "react";
import { HowWeWork } from "../HowWeWork";
import "./styles.css";

const HouseHowWeWork = ({ pageId, title, text, stages, form, repair }) => {
  return (
    <HowWeWork
      pageId={pageId}
      title={title}
      text={text}
      stages={stages}
      form={form}
      repair={repair}
    />
  );
};

HouseHowWeWork.getLivePreviewData = ({ data, getAsset }) => ({
  howWeWork: {
    ...data.howWeWork,
    stages: data.howWeWork.stages.map((stage) => ({
      ...stage,
      icon: {
        publicURL: getAsset(stage.icon).url,
      },
    })),
    repair: {
      ...data.howWeWork.repair,
      image: {
        publicURL: getAsset(data.howWeWork.repair.publicURL),
      },
    },
  },
});

const houseHowWeWorkQuery = graphql`
  fragment HouseHowWeWorkFragment on MarkdownRemarkFrontmatter {
    howWeWork {
      title
      text
      stages {
        name
        icon {
          publicURL
        }
      }

      form {
        subtitle
        title
        text
        buttonLabel
        consentToTheProcessing
      }

      repair {
        image {
          publicURL
        }
        title
        text
      }
    }
  }
`;

export { HouseHowWeWork, houseHowWeWorkQuery };
