import React from "react";
import { graphql } from "gatsby";

import "./styles.css";
import { Issues } from "../Issues";

const HouseIssues = ({ background, title, text, issues }) => {
  return (
    <Issues
      className="HouseIssues"
      background={background}
      title={title}
      text={text}
      issues={issues}
    />
  );
};

HouseIssues.getLivePreviewData = ({ data, getAsset }) => ({
  issues: {
    ...data.issues,
    background: getAsset(data.issues.background).url,
    issues: data.issues.issues.map(issue => ({
      ...issue,
      icon: {
        publicURL: getAsset(issue.icon).url
      }
    }))
  }
});

const houseIssuesQuery = graphql`
  fragment HouseIssuesFragment on MarkdownRemarkFrontmatter {
    issues {
      background {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      title
      text

      issues {
        icon {
          publicURL
        }
        title
        text
      }
    }
  }
`;

export { HouseIssues, houseIssuesQuery };
