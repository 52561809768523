import React from "react";
import { Button } from "../Button";
import { Arrow } from "../../icons/Arrow";
import { Link } from "gatsby";
import cn from "classnames";
import "./styles.css";

const HeroCard = ({ title, text, buttonLabel, Icon, href, className }) => {
  return (
    <Link to={href} className={cn("HeroCard", className)}>
      <div className="HeroCard__heading">
        <Icon />
        <div className="[ HeroCard__title ] [ Font_headerSmall Color_darkBlue ]">
          {title}
        </div>
      </div>
      <div className="[ HeroCard__description ] [ Font_textRegular Color_darkBlue48 ]">
        {text}
      </div>
      <Button
        className="HeroCard__cardButton"
        variant="outlined"
        rightIcon={<Arrow />}
      >
        {buttonLabel}
      </Button>
    </Link>
  );
};

export { HeroCard };
