import React from "react";
import { graphql } from "gatsby";
import { Solutions } from "../Solutions";

import "./styles.css";

const HouseHouseSolutions = ({
  background,
  title,
  name,
  subtitle,
  systems,
  solutions
}) => {
  return (
    <div id="house">
      <Solutions
        background={background}
        title={title}
        name={name}
        subtitle={subtitle}
        systems={systems}
        solutions={solutions}
      />
    </div>
  );
};

HouseHouseSolutions.getLivePreviewData = ({ data, getAsset }) => ({
  houseSolutions: {
    ...data.houseSolutions,
    background: getAsset(data.houseSolutions.background).url,
    solutions: data.houseSolutions.solutions.map(solution => ({
      ...solution,
      photo: getAsset(solution.photo).url
    }))
  }
});

const houseHouseSolutionsQuery = graphql`
  fragment HouseHouseSolutionsFragment on MarkdownRemarkFrontmatter {
    houseSolutions {
      background {
        childImageSharp {
          fluid(maxWidth: 1544) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      title
      name
      subtitle
      systems
      solutions {
        photo {
          childImageSharp {
            fluid(maxWidth: 936) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
        text
        price
      }
    }
  }
`;

export { HouseHouseSolutions, houseHouseSolutionsQuery };
